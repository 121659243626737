


/* register_section start */

.Formulario .register_section{
	float:left;
	width:100%;
	padding-top:100px;
	padding-bottom:100px;
}
.Formulario .register_form_wrapper{
	float:left;
	width:100%;
    text-transform: none;
}
.Formulario .register-tabs.nav-justified>.active>a, 
.Formulario .register-tabs.nav-justified>.active>a:focus, 
.Formulario .register-tabs.nav-justified>.active>a:hover {
	border-bottom-color: #f1f1f1;
	background-color: #fff;
	color: #4285f4;
}
.Formulario .register_tab_wrapper .register-tabs {
	font-size: 10px;
  	border-bottom: none;
	padding-top:0;
	padding-bottom:0;
  	text-transform: uppercase;
}
.Formulario .register_tab_wrapper .register-tabs>li.active {
    position: relative;
}
.Formulario .register_tab_wrapper .register-tabs>li.active::after {
	content: "";
	position: absolute;
	left: 50%;
	bottom: -5px;
	margin-left: -10px;
	border-top: 5px solid #23c0e9;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
}
.Formulario .register_tab_wrapper .register-tabs>li>a {
	line-height: 20px;
	padding: 15px 30px;
	border: 0;
	border-radius: 0px;
	background-color: #f3f3f3;
	color: #6d6d6d;
	font-size:20px;
	/*text-transform:capitalize;*/
	text-transform:none;
	transition: inherit;
	width:300px;
}
.Formulario .register_tab_wrapper .register-tabs>li>a>span {
	font-size:14px;
	/*text-transform:capitalize;*/
    text-transform:none;
	font-weight:400 !important;
}
.custom_input p{
	font-size:14px;
}
.custom_input input{
	margin: 20px 0;
}
.Formulario .register_tab_wrapper .register-tabs>li.active>a, 
.Formulario .register_tab_wrapper .register-tabs>li.active>a:focus, 
.Formulario .register_tab_wrapper .register-tabs>li.active>a:hover {
	color: #fff;
	background-color: #23c0e9;
	border: 0;
}
.Formulario .register_tab_wrapper .tab-content{
	background-color: #f9f9f9;
	padding: 30px;
	float:left;
	border:1px solid #e4e4e4;
	margin-bottom:30px;
	width:100%;
}
.Formulario .register_tab_wrapper .nav > li{
	float:left;
}
.Formulario .register_left_form{
	float:left;
	width:100%;
}
.Formulario .register_left_form .column {
    padding-right: 30px;
}
.Formulario .register_left_form .form-group {
    position: relative;
    margin-bottom: 5px;
}

.Formulario .register_left_form .field-label i {
    float: right;
    font-size: 16px;
    color: #666;
}

.Formulario .register_left_form .form-group .text {
    position: relative;
    color: #bbbbbb;
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 5px;
}
.Formulario input[type="text"],
.Formulario input[type="email"],
.Formulario input[type="password"],
.Formulario input[type="tel"],
.Formulario input[type="number"],
.Formulario input[type="url"],
.Formulario input[type="date"],
.Formulario select,
.Formulario textarea
.Formulario .register_left_form input[type="text"],
.Formulario .register_left_form input[type="email"],
.Formulario .register_left_form input[type="password"],
.Formulario .register_left_form input[type="tel"],
.Formulario .register_left_form input[type="number"],
.Formulario .register_left_form input[type="url"],
.Formulario .register_left_form select,
.Formulario .register_left_form textarea {
    position: relative;
    display: block;
    width: 100%;
    background: #ffffff;
	/*text-transform:capitalize;*/
    text-transform:none;
    font-size: 15px;
    line-height: 26px;
    color: #888888;
    padding: 12px 15px;
	border-radius:7px;
    height: 50px;
    margin-bottom: 15px;
    border: 1px solid #e0e0e0;
    transition: all 300ms ease;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
}

.Formulario .register_left_form textarea {
    resize: none;
    height: 120px !important;
    padding-left: 30px;
}
.Formulario .register_left_form .img-upload img{
	width: 180px;
	height: 180px;
}

.Formulario .register_left_form input:focus,
.Formulario .register_left_form select:focus,
.Formulario .register_left_form textarea:focus {
    border-color: #23c0e9;
}
.Formulario .jp_regiter_top_heading p{
	font-size:16px;
	text-transform:capitalize;
	margin-bottom:20px;
	margin-top:10px;
}
.Formulario .check-box{
	margin-top:35px;
}
.Formulario .checkout-page .check-box {
    line-height: 24px;
    font-size: 14px;
    font-weight: normal;
    padding-top: 5px;
}
.check_box_anchr{
	color:#23c0e9 !important;;
}
.Formulario .checkout-page .check-box label {
    position: relative;
    top: -1px;
    font-weight: normal;
    padding: 0px;
    font-size: 18px;
    cursor: pointer;
    color: #333333;
}
.Formulario .Formulario .register_left_form input[type="checkbox"] + label:before{
	display:none;
}
.Formulario .register_btn_wrapper{
	padding-top:15px;
}
.Formulario .btm_txt_register_form{
	width: 70%;
    text-align: center;
    margin: 0px auto;
    font-size: 14px;
}
/* register_section end */

.Formulario .login_btn_wrapper{
	padding-bottom:20px;
	margin-bottom:30px;
	border-bottom:1px solid #e4e4e4;
	margin: 15px 4px 4px 4px !important;
}
.Formulario .login_btn_wrapper a.login_btn {
   text-align:center;
   text-transform:uppercase;
}
.Formulario .login_btn_wrapper a.login_btn:hover {
    background-color: #00b5e4;
    border-color: #00b5e4;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.Formulario .login_btn_wrapper button.login_btn {
	text-align:center;
	text-transform:uppercase;
	width: 100%;
	height: 50px;
}
.Formulario .login_btn_wrapper button:focus{
	box-shadow: none;
	border: none;
	border-color: transparent;
}
.Formulario .login_btn_wrapper button.login_btn:hover{
	background-color: #00b5e4;
    border-color: #00b5e4;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.Formulario .login_message p{
	text-align:center;
	font-size:16px !important;
	margin:0;
}
.Formulario .login_message a{
	color:#23c0e9;
}
.Formulario .login_form_wrapper p{
	width:70%;
	text-align:center;
	margin:0px auto;
	font-size:14px;
}

.Formulario .form-group {
	margin-top:20px !important;
}

.Formulario .senha-icon {
	float: right;
	margin-right: 8px; 
	margin-top: -51px !important;
	position: relative;
	cursor: pointer; 
	font-size: 22px !important;
	z-index: 2;
}

.Formulario .endereco_bar, .Formulario .servicos_bar, .Formulario .horarios_bar {
	font-size: 25px !important;
	background-color: #23c0e9;
	color:#ffffff;
	border-radius: 5px;
}

.Formulario .servicos_bar {
	margin-top: 20px !important;
	margin: 3px;
}

.Formulario .servicos_info {
	padding:0 5px 0 5px;
}


.Formulario input[type="radio"] {
	margin-top: -1px;
	vertical-align: middle;
	margin-left: 8px;
	width:18px;
	height:18px;
}


.Formulario #q10, .Formulario input[type="time"] {
	height:38px !important;
	padding:5px;
	font-size:15px;
}


.Formulario input[type="checkbox"] {
	width:18px;
	height:18px;
}

.Formulario .horarios_bar
{
	margin-top: 35px;
}


.Formulario .horarios_info table tr td {
	align-content: center;
	align-items: center !important;
	font-size: 17px;
}