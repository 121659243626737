/*.Spotlight .owl-item {
    width:257px !important;
    padding: 0 !important;
    background-color: blue;
}*/





.Spotlight .jp_spotlight_slider_wrapper {
    float: left;
    width: 100%;
    padding-top: 0 !important;
    padding-left: 1px !important;
    padding-right:  1px !important;
    padding-bottom:  0 !important;
    background: #ffffff;
    border: 2px solid  #23c0e9;
    border-top: 0;
    border-bottom: 1px solid #23c0e9;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}

.Spotlight .owl-nav {
    margin-top: 30px !important;
    margin-right: 30px !important;
}