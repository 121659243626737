.fa-2x {
    font-size:32px !important;
}

.hide {
    display: none !important;
}

.gc_main_menu_wrapper {
    background-color: #363B4B;
}