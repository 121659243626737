.pricing_box1_wrapper {
    margin-bottom: 15px !important;
}

.pricing_cont ul li {
    text-align: center;
    margin-left: 15px !important;
    margin-right: 15px !important;
}

.pricing_box1_wrapper {
    height: 600px;
}


.pricing_btn1 ul li {
    white-space: nowrap;
}