.jp_listing_single_main_wrapper{
	float:left;
	width:100%;
	background:#f9f9f9;
	padding-top:60px;
	padding-bottom:60px;
}
.jp_listing_left_sidebar_wrapper{
	float:left;
	width:100%;
	background:#ffffff;
	border:1px solid #e9e9e9;
	padding:60px;
}
.jp_job_des{
	float:left;
	width:100%;
}
.jp_job_des h2{
	font-size:20px;
	font-weight:bold;
	color:#000000;
	text-transform:uppercase;
	position:relative;
}
.jp_job_des h2:after{
	content:'';
	border:1px solid #23c0e9;
	width:30px;
	position:absolute;
	bottom: -15px;
    left: 11px;
}
.jp_job_des h2:before{
	content:'';
	border:1px solid #23c0e9;
	width:8px;
	position:absolute;
	bottom: -15px;
    left: 0;
}
.jp_job_des p{
	padding-top:50px;
}
.jp_job_des ul{
	margin-top:20px;
}
.jp_job_des li{
	float:left;
	margin-left:20px;
}
.jp_job_des li:first-child{
	margin-left:0;
}
.jp_job_des li a{
	font-size:16px;
	color:#797979;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.jp_job_des li a:hover{
	color:#23c0e9;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.jp_job_res{
	float:left;
	width:100%;
	padding-top:60px;
}
.jp_job_res li i{
	color:#ff5353;
}
.jp_job_res h2{
	font-size:20px;
	font-weight:bold;
	color:#000000;
	text-transform:uppercase;
	position:relative;
}
.jp_job_res h2:after{
	content:'';
	border:1px solid #23c0e9;
	width:30px;
	position:absolute;
	bottom: -15px;
    left: 11px;
}
.jp_job_res h2:before{
	content:'';
	border:1px solid #23c0e9;
	width:8px;
	position:absolute;
	bottom: -15px;
    left: 0;
}
.jp_job_res p{
	padding-top:50px;
}
.jp_job_res ul{
	margin-top:30px;
}
.jp_job_res li{
	margin-top:25px;
}
.jp_job_res li:first-child{
	margin-top:0;
}
.jp_job_qua ul{
	margin-top:50px;
}
.jp_job_apply{
	float:left;
	width:100%;
	padding-top:60px;
}
.jp_job_apply h2{
	font-size:20px;
	font-weight:bold;
	color:#000000;
	text-transform:uppercase;
	position:relative;
}
.jp_job_apply h2:after{
	content:'';
	border:1px solid #23c0e9;
	width:30px;
	position:absolute;
	bottom: -15px;
    left: 11px;
}
.jp_job_apply h2:before{
	content:'';
	border:1px solid #23c0e9;
	width:8px;
	position:absolute;
	bottom: -15px;
    left: 0;
}
.jp_job_apply p{
	padding-top:50px;
}
.jp_job_map{
	float:left;
	width:100%;
	padding-top:60px;
}
.jp_job_map h2{
	font-size:20px;
	font-weight:bold;
	color:#000000;
	text-transform:uppercase;
	position:relative;
}
.jp_job_map h2:after{
	content:'';
	border:1px solid #23c0e9;
	width:30px;
	position:absolute;
	bottom: -15px;
    left: 11px;
}
.jp_job_map h2:before{
	content:'';
	border:1px solid #23c0e9;
	width:8px;
	position:absolute;
	bottom: -15px;
    left: 0;
}
.jp_listing_left_bottom_sidebar_wrapper{
	float:left;
	width:100%;
	border:1px solid #e9e9e9;
	padding: 60px;
	background:#ffffff;
	border-top:0;
	padding-top:50px;
	padding-bottom:50px;
}
.jp_listing_left_bottom_sidebar_social_wrapper ul{
	
}
.jp_listing_left_bottom_sidebar_social_wrapper li{
	float:left;
	margin-left:10px;
}
.jp_listing_left_bottom_sidebar_social_wrapper li:first-child{
	margin-left:0;
	color:#000000;
	font-weight:bold;
	margin-top: 12px;
}
.jp_listing_left_bottom_sidebar_social_wrapper li a{
	float:left;
	width:50px;
	height:50px;
	-webkit-border-radius:20px;
	-moz-border-radius:20px;
	border-radius:20px;
	background:transparent; 
	text-align:center;
	line-height:50px;
	color:#797979;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.jp_listing_left_bottom_sidebar_social_wrapper li a:hover{
	background:#23c0e9; 
	color:#ffffff;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.jp_listing_left_bottom_sidebar_key_wrapper{
	float:left;
	width:100%;
	padding:60px;
	padding-top:20px;
	padding-bottom:20px;
	border:1px solid #e9e9e9;
	background:transparent;
	border-top:0;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.jp_listing_left_bottom_sidebar_key_wrapper li{
	float:left;
	margin-left:20px;
}
.jp_listing_left_bottom_sidebar_key_wrapper li:first-child{
	margin-left:0;
	color:#000000;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.jp_listing_left_bottom_sidebar_key_wrapper li i{
	padding-right:5px;
	color:#23c0e9;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.jp_listing_left_bottom_sidebar_key_wrapper li a{
	color:#797979;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.jp_listing_related_heading_wrapper{
	float:left;
	width:100%;
	margin-top:60px;
}
.jp_listing_related_slider_wrapper{
	float:left;
	width:100%;
	margin-top: 50px;
}
.jp_listing_related_slider_wrapper  .owl-theme .owl-dots{
	display:none;
}
.jp_listing_related_slider_wrapper .owl-theme .owl-nav {
    position: absolute;
    top: -74px;
    margin: 0;
    left: 0;
    right: 0;
	z-index:1000;
	display:block;
}
.jp_listing_related_slider_wrapper .owl-theme .owl-nav .owl-prev{
    font-size: 12px;
	float:left;
	width:60px;
	height:30px;
	line-height:30px;
	background:#ffffff;
    margin: 0;
    padding: 0;
    right: 75px;
	top:0;
	color:#000000;
	-webkit-border-radius:10px;
	-moz-border-radius:10px;
	border-radius:10px;
	border:1px solid #e9e9e9;
    position: absolute;
    text-align: center;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.jp_listing_related_slider_wrapper  .owl-theme .owl-nav .owl-next{
    font-size: 12px;
	float:left;
	width:60px;
	height:30px;
	line-height:30px;
    margin: 0;
    padding: 0;
    right: 0;
	top:0;
	right:0;
    position: absolute;
	color:#000000;
	background:#ffffff;
	-webkit-border-radius:10px;
	-moz-border-radius:10px;
	border-radius:10px;
	border:1px solid #e9e9e9;
    text-align: center;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.jp_listing_related_slider_wrapper .owl-theme .owl-nav .owl-prev:hover, .jp_listing_related_slider_wrapper  .owl-theme .owl-nav .owl-next:hover{
	background:#23c0e9;
	border:1px solid #23c0e9;
	color:#ffffff;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.jp_listing_related_heading_wrapper h2{
	font-size:20px;
	color:#000000;
	font-weight:bold;
	position:relative;
	text-transform:uppercase;
}
.jp_listing_related_heading_wrapper h2:after{
	content:'';
	border:1px solid #23c0e9;
	width:30px;
	position:absolute;
	bottom: -15px;
    left: 11px;
}
.jp_listing_related_heading_wrapper h2:before{
	content:'';
	border:1px solid #23c0e9;
	width:8px;
	position:absolute;
	bottom: -15px;
    left: 0;
}
.jp_rightside_listing_single_wrapper{
	margin-top:0;
}
.jp_jop_overview_img_wrapper{
	text-align:center;
}
.jp_jop_overview_img{
	display:inline-block;
	padding-top:30px;
}
.jp_rightside_listing_single_wrapper{
	float:left;
	width:100%;
	border: 1px solid #e9e9e9;
	background:#ffffff;
}
.jp_job_listing_single_post_right_cont{
	float:left;
	width:100%;
	text-align:center;
}
.jp_job_listing_single_post_right_cont_wrapper{
	display:inline-block;
}
.jp_job_listing_single_post_right_cont_wrapper h4{
	font-size:16px;
	color:#000000;
	font-weight:bold;
	padding-top:40px;
}
.jp_job_listing_single_post_right_cont_wrapper p{
	font-size:16px;
	color:#23c0e9;
	padding-top:10px;
}
.jp_job_post_right_overview_btn_wrapper{
	text-align:center;
	padding-bottom:50px;
	border-bottom:1px solid #e9e9e9;
}
.jp_job_post_right_overview_btn ul{
	display:inline-block;
	margin-top: 15px;
}
.jp_job_post_right_overview_btn li{
	float:left;
	margin-left:20px;
}
.jp_job_post_right_overview_btn li:first-child{
	margin-left:0;
} 
.jp_job_post_right_overview_btn li:first-child a{
	float:left;
	width:30px;
	height:30px;
	border:1px solid #e9e9e9;
	text-align:center;
	line-height:30px;
	-webkit-border-radius:10px;
	-moz-border-radius:10px;
	border-radius:10px;
	color:#f36969;
	background:transparent;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
} 
.jp_job_post_right_overview_btn li:first-child a:hover{
	background:#f36969;
	color:#ffffff;
	border:1px solid #f36969;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.jp_job_post_right_overview_btn li:last-child a{
	float:left;
	width:100px;
	height:30px;
	line-height:30px;
	text-align:center;
	background:#37d09c;
	color:#ffffff;
	font-size:12px;
	text-transform:uppercase;
	-webkit-border-radius:10px;
	-moz-border-radius:10px;
	border-radius:10px;
}
.jp_listing_overview_list_main_wrapper{
	float:left;
	width:100%;
}
.jp_listing_overview_list_main_wrapper2{
	padding-top:40px;
}
.jp_listing_overview_list_outside_main_wrapper{
	float:left;
	width:100%;
	padding:50px;
}
.jp_listing_list_icon{
	float:left;
	width:20px;
}
.jp_listing_list_icon_cont_wrapper{
	float:left;
	width:calc(100% - 20px);
	padding-left:10px;
}
.jp_listing_list_icon_cont_wrapper ul{
	
}
.jp_listing_list_icon i{
	color:#ff5353;
}
.jp_listing_list_icon_cont_wrapper li:first-child{
	font-weight:bold;
	color:#000000;
}
.jp_listing_right_bar_btn_wrapper{
	float:left;
	width:100%;
	text-align:center;
}
.jp_listing_right_bar_btn ul{
	display:inline-block;
	margin-top:40px;
}
.jp_listing_right_bar_btn li:first-child a{
	float:left;
	width:230px;
	height:50px;
	text-align:center;
	line-height:50px;
	color:#ffffff;
	text-transform:uppercase;
	font-weight:bold;
	background:#23c0e9;
	-webkit-border-radius: 10px; 
  -moz-border-radius: 10px; 
   border-radius: 10px; 
   -webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.jp_listing_right_bar_btn li:first-child a:hover{
	background:#f36964;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.jp_listing_right_bar_btn li:last-child{
	margin-top:70px;
}
.jp_listing_right_bar_btn li:last-child a{
	float:left;
	width:230px;
	height:50px;
	text-align:center;
	line-height:50px;
	color:#ffffff;
	text-transform:uppercase;
	font-weight:bold;
	background:#ff5353;
	-webkit-border-radius: 10px; 
  -moz-border-radius: 10px; 
   border-radius: 10px; 
   -webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.jp_listing_right_bar_btn li:last-child a:hover{
	background:#23c0e9;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}


.jp_job_des p {
	padding-top: 15px;
}

.ReclameAqui input[type="checkbox"], .ReclameAqui input[type="radio"] {
	width:18px;
	height:18px;
}



.ReclameAqui label {
    display: block;
    padding-left: 15px;
    text-indent: -15px;
  }


.ReclameAqui label input[type="checkbox"], .ReclameAqui label input[type="radio"] {
    width: 25px;
    height: 25px;
    padding: 0;
    margin:0;
    vertical-align: bottom;
    position: relative;
    top: 1px;
    *overflow: hidden;
}

.hide {
	display: none;
}

#comments {
	width: 100%;
	height: 100px;
}

.av_btn {
	padding: 13px;
	font-size: 17px;
}

.btn_contato {
	margin-top: -40px;
}