.jp_contact_form_main_wrapper{
	float:left;
	width:100%;
	background:#f9f9f9;
	padding-top:100px;
	padding-bottom:100px;
}
.jp_contact_form_heading_wrapper{
	float:left;
	width:100%;
}
.jp_contact_form_heading_wrapper h2{
	font-size:20px;
	font-weight:bold;
	color:#000000;
	position:relative;
	text-transform:uppercase;
}
.jp_contact_form_heading_wrapper h2:after{
	content:'';
	border:1px solid #23c0e9;
	width:30px;
	position:absolute;
	bottom: -15px;
    left: 11px;
}
.jp_contact_form_heading_wrapper h2:before{
	content:'';
	border:1px solid #23c0e9;
	width:8px;
	position:absolute;
	bottom: -15px;
    left: 0;
}
.jp_contact_form_box{
	float:left;
	width:100%;
	border:1px solid #e9e9e9;
	border-bottom:1px solid #23c0e9;
	padding:50px;
	background:#ffffff;
	margin-top:60px;
}
.jp_contact_inputs_wrapper input{
	width:100%;
	height:50px;
	border:1px solid #e9e9e9;
	-webkit-border-radius: 10px; 
  -moz-border-radius: 10px; 
   border-radius: 10px; 
   padding-left:45px;
   position:relative;
   -webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.jp_contact_inputs_wrapper input:hover, .jp_contact_inputs_wrapper input:focus{
	border:1px solid #23c0e9;
	border-top:1px solid #e9e9e9;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.jp_contact_inputs_wrapper i{
	position: absolute;
    z-index: 1;
    top: 18px;
    left: 40px;
	color:#ff5353;
}
.jp_contact_inputs3_wrapper{
	margin-top:30px;
}
.jp_contact_inputs3_wrapper i{
	top:48px;
}
.jp_contact_inputs4_wrapper textarea{
	width:100%;
	padding-left:45px;
	margin-top:30px;
	position:relative;
	padding-top:15px;
	-webkit-border-radius: 10px; 
  -moz-border-radius: 10px; 
   border-radius: 10px; 
   resize:none;
	border:1px solid #e9e9e9;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.jp_contact_inputs4_wrapper textarea:hover, .jp_contact_inputs4_wrapper textarea:focus{
	border:1px solid #23c0e9;
	border-top:1px solid #e9e9e9;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.jp_contact_inputs4_wrapper i{
	position:absolute;
	color:#ff5353;
	top: 51px;
	z-index:1000;
}
.jp_contact_form_btn_wrapper ul{
	margin-top:30px;
}
.jp_contact_form_btn_wrapper li{
	float:left;
}
.jp_contact_form_btn_wrapper li a{
	float:left;
	width:160px;
	height:50px;
	line-height:45px;
	color:#ffffff;
	border:1px solid #f36969;
	background:#f36969;
	text-align:center;
	text-transform: uppercase;
	-webkit-border-radius:10px;
	-moz-border-radius:10px;
	border-radius:10px;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.jp_contact_form_btn_wrapper li a:hover{
	background:#23c0e9;
	border:1px solid #ffffff40;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.jp_contact_right_box_wrapper{
	float:left;
	width:100%;
	background:#23c0e9;
	padding: 50px;
	margin-top:60px;
    z-index: 1;
}
.gc_get_heading_icon{
	float:left;
	width:30px;
	padding-top: 13px;
}
.gc_get_pera{
	float:left;
	width:100%;
}
.gc_get_pera p{
	color:rgba(255, 255, 255, 0.78);
	padding-top:30px;
}
.gc_get_pera p a{
	color:#00468c;
	font-style:italic;
	padding-left:5px;
}
.jp_form_add_wrapper{
	float:left;
	width:100%;
}
.gc_map_add_wrapper1{
	padding-top:45px;	
}
.gc_map_add_wrapper2, .gc_map_add_wrapper3{
	padding-top:25px;	
}
.jp_map_location_icon_wrapper{
	width:20px;
	height:50px;
	float:left;
	line-height:50px;
	font-size:22px;
	color:#ffffff96;
}
.gc_map_location_icon_cont_wrapper{
	float:left;
	width:calc(100% - 20px);
	padding-left:10px;
}
.gc_map_location_icon_cont_wrapper h3{
	font-size:16px;
	color:#ffffff;
	padding-top: 10px;
	font-family: 'Lato', sans-serif;
}
.gc_map_location_icon_cont_wrapper h3 a{
	color:#ffffff;
}
.gc_map_add_wrapper3 .jp_map_location_icon_wrapper{
	font-size:15px !important;
	line-height: 45px !important;
}
.jp_contact_form_add_heading_wrapper{
	float:left;
	width:100%;
}
.jp_contact_form_add_heading_wrapper h2{
	font-size:20px;
	font-weight:bold;
	color:#ffffff;
	position:relative;
	text-transform:uppercase;
}
.jp_contact_form_add_heading_wrapper h2:after{
	content:'';
	border:1px solid #ffffff;
	width:30px;
	position:absolute;
	bottom: -15px;
    left: 11px;
}
.jp_contact_form_add_heading_wrapper h2:before{
	content:'';
	border:1px solid #ffffff;
	width:8px;
	position:absolute;
	bottom: -15px;
    left: 0;
}
.jp_contact_form_social_icon{
	float:left;
	width:100%;
	padding-top:20px;
}
.jp_contact_form_social_icon li:first-child{
	margin-left:0;
}
.jp_contact_form_social_icon li{
	float:left;
	margin-left:10px;
}
.jp_contact_form_social_icon li a{
	float:left;
	width:40px;
	height:40px;
	-webkit-border-radius:15px;
	-moz-border-radius:15px;
	border-radius:15px;
	background:transparent; 
	text-align:center;
	line-height:40px;
	color:#ffffff8c;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.jp_contact_form_social_icon li a:hover{
	background:#ffffff; 
	color:#000000;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.gc_map_location_icon_cont_wrapper2 h3, .gc_map_location_icon_cont_wrapper4 h3{
	padding-top:12px;
}
.gc_map_location_icon_cont_wrapper3 h3{
	padding-top:16px;
}

.btn_contato {
	float: left;
	width: 160px !important;
	height: 50px !important; 
	line-height: 45px !important;
	color: #ffffff;
	border: 1px solid #f36969;
	background: #f36969;
	text-align: center;
	text-transform: uppercase;
	border-radius: 10px;
	transition: all 0.5s;
}

.btn_contato:hover {
	background: #b33535;
}