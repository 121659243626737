.Breadcrumb .jp_tittle_main_wrapper {
	/*background:url('/images/content/pricing_tittle_bg.jpg') 50% 0 repeat-y;*/
	background-repeat:no-repeat;
	background-size:cover;
	background-position:center 0;
	position:relative;
	float:left;
	width:100%;
	padding-top:25px;
	padding-bottom:25px;
} 


.Breadcrumb .jp_tittle_img_overlay{
	position:absolute;
	top:0%;
	right:0%;
	left:0%;
	bottom:0%;
	background:#0e1427d9;
}


.Breadcrumb .jp_tittle_heading h2{
	font-size:36px;
	color:#ffffff;
	font-weight:bold;
}	


.Breadcrumb .jp_tittle_breadcrumb_wrapper li{
	margin-left:20px;
	float:left;
}
.Breadcrumb .jp_tittle_breadcrumb_wrapper li a{
	color:#ffffffa6;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.Breadcrumb .jp_tittle_breadcrumb_wrapper li a:hover{
	color:#23c0e9;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.Breadcrumb .jp_tittle_breadcrumb_wrapper li:first-child{
	margin-left:0;
}
.Breadcrumb .jp_tittle_breadcrumb_wrapper li i{
	color:#23c0e9;
	position: relative;
    left: 10px;
}
.Breadcrumb .jp_tittle_breadcrumb_wrapper li:last-child{
	color:#23c0e9;
}