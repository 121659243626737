.Formulario .orcamento-banner {
   overflow: hidden;
}

.Formulario .col-md-6 {
    padding-left: 2px;
    padding-right: 2px;
}

.Formulario .register_tab_wrapper .register-tabs>li>a {
    width:auto !important;
}

.Formulario label {
    display: block;
    padding-left: 15px;
    text-indent: -15px;
  }


.Formulario label input[type="checkbox"] {
    width: 25px;
    height: 25px;
    padding: 0;
    margin:0;
    vertical-align: bottom;
    position: relative;
    top: 1px;
    *overflow: hidden;
}