.Page .jp_cp_profile_main_wrapper {
	float: left;
	width: 100%;
	padding-top: 100px;
	padding-bottom: 100px;
}

.Page .jp_cp_left_side_wrapper {
	float: left;
	width: 100%;
}

.Page .jp_cp_left_pro_wallpaper {
	float: left;
	width: 100%;
	text-align: center;
}

.Page .jp_cp_left_pro_wallpaper img {
	width: 100%;
}

.Page .jp_cp_left_pro_wallpaper h2 {
	font-size: 30px;
	font-weight: 600;
	color: #000000;
	padding-top: 30px;
	text-align: center;
}

.Page .jp_cp_left_pro_wallpaper p {
	font-size: 18px;
	padding-top: 10px;
	text-align: center;
}

.Page .jp_cp_left_pro_wallpaper ul {
	display: inline-block;
}

.Page .jp_cp_left_pro_wallpaper li {
	float: left;
	margin-right: 10px;
}

.Page .jp_cp_left_pro_wallpaper li a {
	float: left;
	width: 50px;
	height: 50px;
	line-height: 50px;
	text-align: center;
	background: #eee;
	color: #000000;
	-webkit-border-radius: 100%;
	-moz-border-radius: 100%;
	border-radius: 100%;
	-webkit-transition: all .5s;
	-moz-transition: all .5s;
	-ms-transition: all .5s;
	-o-transition: all .5s;
	transition: all .5s;
}

.Page .jp_cp_left_pro_wallpaper li a:hover {
	background: #23c0e9;
	color: #ffffff;
	-webkit-transition: all .5s;
	-moz-transition: all .5s;
	-ms-transition: all .5s;
	-o-transition: all .5s;
	transition: all .5s;
}

.Page .jp_cp_rd_wrapper {
	float: left;
	width: 100%;
}

.Page .jp_cp_rd_wrapper ul {
	margin-top: 30px;
}

.Page .jp_cp_rd_wrapper li:first-child a {
	float: left;
	width: 100%;
	height: 50px;
	text-align: center;
	line-height: 50px;
	background: #23c0e9;
	color: #ffffff;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	font-size: 18px;
	font-weight: 600;
	-webkit-transition: all .5s;
	-moz-transition: all .5s;
	-ms-transition: all .5s;
	-o-transition: all .5s;
	transition: all .5s;
}

.Page .jp_cp_rd_wrapper li:first-child a:hover {
	background: #f53157;
	-webkit-transition: all .5s;
	-moz-transition: all .5s;
	-ms-transition: all .5s;
	-o-transition: all .5s;
	transition: all .5s;
}

.Page .jp_cp_rd_wrapper li:last-child a {
	float: left;
	width: 100%;
	height: 50px;
	margin-top: 20px;
	text-align: center;
	line-height: 50px;
	background: #f53157;
	color: #ffffff;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	font-size: 18px;
	font-weight: 600;
	-webkit-transition: all .5s;
	-moz-transition: all .5s;
	-ms-transition: all .5s;
	-o-transition: all .5s;
	transition: all .5s;
}

.Page .jp_cp_rd_wrapper li:last-child a:hover {
	background: #23c0e9;
	-webkit-transition: all .5s;
	-moz-transition: all .5s;
	-ms-transition: all .5s;
	-o-transition: all .5s;
	transition: all .5s;
}

.Page .jp_cp_right_side_wrapper {
	float: left;
	width: 100%;
}

.Page .jp_cp_right_side_inner_wrapper {
	float: left;
	width: 100%;
}

.Page .jp_cp_right_side_inner_wrapper h2 {
	font-size: 20px;
	font-weight: bold;
	color: #000000;
	text-transform: uppercase;
	position: relative;
}

.Page .jp_cp_right_side_inner_wrapper h2:before {
	content: '';
	border: 1px solid #23c0e9;
	width: 8px;
	position: absolute;
	bottom: -15px;
	left: 0;
}

.Page .jp_cp_right_side_inner_wrapper h2:after {
	content: '';
	border: 1px solid #23c0e9;
	width: 30px;
	position: absolute;
	bottom: -15px;
	left: 12px;
}

.Page .jp_cp_right_side_inner_wrapper table {
	margin-top: 30px;
}

.Page .jp_cp_right_side_inner_wrapper>table td {
	padding: 10px 30px 10px 0px;
	font-size: 18px;
	font-weight: 400;
	line-height: 40px;
}

.td-w65 {
	width: 65%;
	color: #111111;
	font-style: italic;
}

.td-w25 {
	width: 25%;
}

.td-w10 {
	width: 10%;
}

.Page .jp_cp_accor_heading_wrapper {
	float: left;
	width: 100%;
	margin-top: 60px;
}

.Page .jp_cp_accor_heading_wrapper h2 {
	font-size: 20px;
	font-weight: bold;
	color: #000000;
	text-transform: uppercase;
	position: relative;
}

.Page .jp_cp_accor_heading_wrapper h2:before {
	content: '';
	border: 1px solid #23c0e9;
	width: 8px;
	position: absolute;
	bottom: -15px;
	left: 0;
}

.Page .jp_cp_accor_heading_wrapper h2:after {
	content: '';
	border: 1px solid #23c0e9;
	width: 30px;
	position: absolute;
	bottom: -15px;
	left: 12px;
}

.Page .jp_cp_accor_heading_wrapper p {
	padding-bottom: 30px;
	font-size: 16px;
	margin-top: 30px;
}

.Page .jp_cp_left_ad_res {
	float: left;
	width: 100%;
}

.Page .jp_job_location_wrapper {
	margin-top: 40px;
}

.Page .abt_page_2_wrapper .panel .panel-heading a.collapsed {
	background: #23c0e9 !important;
}

.Page .abt_page_2_wrapper .panel .panel-heading.desktop a.collapsed:before {
	color: #fff;
}

.Page .abt_page_2_wrapper .panel .panel-heading.horn a.collapsed:before {
	color: #fff;
}

.Page .abt_page_2_wrapper .panel .panel-heading.bell a.collapsed:before {
	color: #fff;
}

.Page .abt_page_2_wrapper .panel .panel-heading a {
	background: #bd6495;
}

.Page .abt_page_2_wrapper .panel .panel-heading.desktop a:before {
	color: #fff;
}

.Page .abt_page_2_wrapper .panel .panel-heading.horn a:before {
	color: #fff;
}

.Page .abt_page_2_wrapper .panel .panel-heading.bell a:before {
	color: #fff;
}

.Page .abt_page_2_wrapper .panel-group .panel-heading+.panel-collapse>.panel-body {
	background: #f7f7f7;
	color: #111111;
}

.Page .accordion_wrapper {
	float: left;
	width: 100%;
}

.Page .accordion_wrapper .panel-heading {
	padding: 0px;
	border-radius: 0px;
}

.Page .accordion_wrapper .panel-heading h4 {
	font-size: 18px;
	line-height: 24px;
}

.Page .accordion_wrapper h2 {
	font-size: 28px;
	font-weight: 800;
	color: #fff;
	line-height: 22px;
	text-transform: uppercase;
	margin-bottom: 24px;
}

.Page .accordion_wrapper .panel-group .panel+.panel {
	margin-top: 0px;
}

.Page .accordion_wrapper .panel-group {
	margin-bottom: 0;
}

.Page .accordion_wrapper .panel-default {
	border: 0;
}

.Page .accordion_wrapper .panel-default>.panel-heading {
	border-radius: 0px;
}

.Page .accordion_wrapper .panel-default .panel-heading {
	background: transparent;
}

.Page .accordion_wrapper .panel {
	background-color: transparent;
	box-shadow: none;
	border-left: 1px solid transparent;
	border-right: 1px solid transparent;
	border-radius: 0;
	margin: 0;
	border-bottom: 1px solid rgba(255, 255, 255, 0.16);
}

.Page .accordion_wrapper .panel:last-of-type {
	border-bottom: 1px solid transparent;
}

.Page .accordion_wrapper .panel .panel-heading a {
	display: block;
	background: #23c0e9;
	padding: 12px 20px;
	color: #fff;
}

.Page .accordion_wrapper .panel .panel-heading a.collapsed {
	color: #fff;
	background: rgba(108, 89, 89, 0.3);
	display: block;
	padding: 12px 20px;
	border-top: 1px solid transparent;
}

.Page .accordion_wrapper .panel-group .panel-heading+.panel-collapse>.panel-body {
	border-top: 0;
	padding-top: 0;
	padding: 25px 30px 30px 70px;
	background: #eee;
	color: #797979;
	font-size: 16px;
	line-height: 24px;
}

.Page .accordion_wrapper .panel .panel-heading a:before,
.Page .accordion_wrapper .panel .panel-heading a.collapsed:before {
	font-family: 'FontAwesome';
	font-size: 20px;
	float: left;
	margin-right: 20px;
}

.Page .accordion_wrapper .panel .panel-heading.desktop a:before {
	content: "\f303";
	color: #ffffff;
}

.Page .accordion_wrapper .panel .panel-heading.desktop a.collapsed:before {
	content: "\f108";
	color: #ffffff;
}

.Page .accordion_wrapper .panel .panel-heading.horn a:before {
	content: "\f0a1";
	color: #ffffff;
}

.Page .accordion_wrapper .panel .panel-heading.horn a.collapsed:before {
	content: "\f0a1";
	color: #ffffff;
}

.Page .accordion_wrapper .panel .panel-heading.bell a:before {
	content: "\f0f3";
	color: #ffffff;
}

.Page .accordion_wrapper .panel .panel-heading.bell a.collapsed:before {
	content: "\f0f3";
	color: #ffffff;
}

.Page .accordion_wrapper .panel .panel-heading a:after,
.Page .accordion_wrapper .panel .panel-heading a.collapsed:after {
	font-size: 14px;
	float: right;
	width: 25px;
	height: 25px;
	line-height: 24px;
	text-align: center;
	display: block;
	border-radius: 50%;
}

.Page .accordion_wrapper .panel .panel-heading a:after {
	content: "\2212";
	color: #fff;
	border: 1px solid #fff;
}

.Page .accordion_wrapper .panel .panel-heading a.collapsed:after {
	content: "+";
	color: #fff;
	border: 1px solid #fff;
}

.aboutus_page_2_section {
	float: left;
	width: 100%;
	padding-bottom: 100px;
	padding-top: 100px;
}